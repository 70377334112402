import "whatwg-fetch";

// TODO: (cs) This is a copy of ../../../api/src/PageFetcher
//       Direct import (in ShadowCmsApplication) failed during ci with:
//         Module not found: Error: Can't resolve '../../../api/src/PageFetcher' in '/build/src/impl'
export enum PageStatus {
    Ok,
    NotFound,
}

export interface PagePreviewOpts {
    preview: boolean;
    frontendCookie: string;
    backendCookie: string;
    showUnpublishedElements: boolean;
}

export interface NoPagePreviewOpts {
    preview: boolean;
}

export const NO_PREVIEW: NoPagePreviewOpts = {
    preview: false
};

export interface GetPageOpts {
    url: string;
    cookie: string;
    invalidateCache: boolean;
    previewOpts: PagePreviewOpts | NoPagePreviewOpts;
}

export interface PostFormOpts {
    url: string;
    method: string;
    enctype: string;
    formData: FormData;
    cookie: string;
}

export interface ApiOptions {
    apiBase: string
}

export default class Api {
    private apiBase: string;

    constructor(opts: ApiOptions) {
        this.apiBase = opts.apiBase;
    }

    async fetch(relativePath: string, args: any): Promise<any> {
        try {
            return await fetch(`${this.apiBase}${relativePath}`, args);
        } catch (e) {
            console.error(e);
        }
    }

    async doJsonPostRequest(relativePath: string, body: any, headers?: Record<string, string>): Promise<any> {

        if (headers === undefined)
            headers = {};

        if (!headers["Content-Type"])
            headers["Content-Type"] = "application/json";

        return await this.fetch(relativePath, {
            method: "post",
            mode: "cors",
            cache: "no-cache",
            redirect: "error",
            credentials: "same-origin",
            headers,
            body: JSON.stringify(body)
        });
    }

    async getPage(opts: GetPageOpts): Promise<any> {

        const response = await this.doJsonPostRequest("/page", {
            url: opts.url,
            previewOpts: opts.previewOpts,
            invalidateCache: opts.invalidateCache,
            cookie: opts.cookie
        });

        const obj = await response.json();
        // console.log(obj);

        if (response.status >= 400) {

            if (obj["message"] !== undefined)
                throw Error(obj.message);
            else
                throw Error(response.statusText); // TODO: Error message
        }

        return obj;
    }

    async postForm(opts: PostFormOpts): Promise<any> {

        const formDataObj = {};
        for (const entry of opts.formData.entries()) {
            if (typeof (entry[1]) == "string") {
                Object.assign(formDataObj, { [entry[0]]: entry[1] });
            } else {
                console.log(typeof (entry[1]));
            }
        }

        const data = JSON.stringify(formDataObj);

        const response = await this.doJsonPostRequest("/form", {
            url: opts.url,
            method: opts.method,
            enctype: opts.enctype,
            data,
            cookie: opts.cookie
        });

        const obj = await response.json();
        // console.log(obj);

        if (response.status >= 400) {

            if (obj["message"] !== undefined)
                throw Error(obj.message);
            else
                throw Error(response.statusText); // TODO: Error message
        }

        return obj;

    }


}
