import "./set-public-path";

import "single-spa";
import { HostedApplication } from "./HostedApplication";
import { CmsApiImpl } from "./impl/CmsApiImpl";
import { CmsApi } from "./CmsApi";
import { ShadowCmsApplication } from "./impl/ShadowCmsApplication";
import debug from "debug";

declare const process: {
    env: {
        [T: string]: string
    }
};

interface LifecycleProperties {
    // The name of the application
    name: string;

    // The singleSpa instance
    singleSpa: any;

    // other args
    [T: string]: any;
}

interface AppOptions {
    hostedApplication: HostedApplication;
}

const logger = debug("app:main");

class App {
    private name?: string = undefined;

    constructor(private options: AppOptions) {
    }

    rootObject(): HTMLDivElement {
        if (!this.name)
            throw Error("Not mounted");
        const id = `#${CSS.escape(`single-spa-application:${this.name}`)}`;
        return document.querySelector(id) as HTMLDivElement;
    }

    async bootstrap(args: LifecycleProperties): Promise<void> {
        logger("bootstraping");
        await this.options.hostedApplication.init();
    }

    async mount(args: LifecycleProperties): Promise<void> {
        this.name = args.name;
        logger(`mounting application ${this.name}`);
        await this.options.hostedApplication.mount(this.rootObject());
    }

    async unmount(args: LifecycleProperties): Promise<void> {
        this.name = undefined;
        logger(`unmounting application ${this.name}`);
        await this.options.hostedApplication.unmount();
    }
}


const api: CmsApi = new CmsApiImpl({
    basePath: process.env.API_BASE
});

const hostedApplication: HostedApplication = new ShadowCmsApplication({
    api
});

const app = new App({
    hostedApplication
});

function wrapErrors<T>(fun: (args: LifecycleProperties) => Promise<void>): (args: LifecycleProperties) => Promise<void> {
    return async (args) => {
        try {
            await fun(args);
        } catch (e) {
            logger(e);
        }
    };
}

export const bootstrap = wrapErrors(app.bootstrap.bind(app));
export const mount = wrapErrors(app.mount.bind(app));
export const unmount = wrapErrors(app.unmount.bind(app));
