import { HostedApplication } from "../HostedApplication";
import {CmsApi, CmsApiFetchPageOptions, CmsApiFetchPageResponse} from "../CmsApi";
import debug from "debug";
import {NO_PREVIEW, NoPagePreviewOpts, PagePreviewOpts, PageStatus} from "./Api";

const logger = debug("app:shadow-cms-application");

export interface ShadowCmsApplicationOptions {
	api: CmsApi
}

interface PageMetaInformation {
	title?: string;
	description?: string;
	robots?: string;
}

export class ShadowCmsApplication implements HostedApplication {

	private browserBackWorkaroundIsRefreshDueToUrlChange: boolean = false;
	private browserBackWorkaroundCurrentUrl: string = "";
	private browserBackWorkaroundInterval: number = -1;

	private parent?: HTMLDivElement;
	private mainObject?: HTMLDivElement;
	private shadowRoot?: ShadowRoot;
	private shadowElements?: {
		html: HTMLHtmlElement;
		head: HTMLHeadElement;
		body: HTMLBodyElement;
	};

	private loadedStylesheets: Record<string, HTMLLinkElement> = {};
	private loadedJavascripts: Record<string, HTMLScriptElement> = {};

	private readonly titlePrefix:string=' | Langenscheidt';

	constructor(private options: ShadowCmsApplicationOptions) {
	}

	init(): Promise<void> {
		return Promise.resolve(undefined);
	}

	async mount(parent: HTMLDivElement): Promise<void> {
		this.initBrowserBackWorkaround();
		this.parent = parent;
		logger("Mounting shadow cms application");
		await this.refresh(false);
	}

	unmount(): Promise<void> {
		logger("Unmounting shadow cms application");
		if (this.browserBackWorkaroundInterval > -1) {
			window.clearInterval(this.browserBackWorkaroundInterval);
		}
		return Promise.resolve(undefined);
	}

	async initializeShadowDom() {

		if (!this.parent)
			throw new Error("Not mounted");

		this.loadFontAwesome();

		this.mainObject = document.createElement("div") as HTMLDivElement;
		this.mainObject.attachShadow({ mode: "open" });
		this.shadowRoot = this.mainObject.shadowRoot as ShadowRoot;

		this.shadowRoot.innerHTML = "";

		const html = document.createElement("html") as HTMLHtmlElement;

		const head = document.createElement("head") as HTMLHeadElement;
		const body = document.createElement("body") as HTMLBodyElement;

		html.appendChild(head);
		html.appendChild(body);

		//const meta = document.createElement("meta") as HTMLMetaElement;

		this.shadowElements = {
			html, head, body
		};

		this.shadowRoot.appendChild(html);
		this.parent.appendChild(this.mainObject);
	}

	updateTitle(text: string): void {
		const head = document.head;
		let title = head.querySelector("title");
		if (!title) {
			title = document.createElement("title");
			head.appendChild(title);
		}
		let actualTitle = `${text}`;
		if (!actualTitle.endsWith(this.titlePrefix)) {
			actualTitle=`${actualTitle}${this.titlePrefix}`;
		}
		logger(`Updating Page title to ${actualTitle}`);
		title.innerHTML = actualTitle;
	}

	updateRobots(text: string): void {
		const head = document.head;
		let robots = head.querySelector("meta[name=robots]");
		if (!robots) {
			robots = document.createElement("meta");
			robots.setAttribute("name", "robots");
			head.appendChild(robots);
		}
		logger(`Updating Page robots to ${text}`);
		robots.setAttribute("content", text);
	}

	updateDescription(text: string): void {
		const head = document.head;
		let description = head.querySelector("meta[name=description]");
		if (!description) {
			description = document.createElement("meta");
			description.setAttribute("name", "description");
			head.appendChild(description);
		}
		logger(`Updating Page description to ${text}`);
		description.setAttribute("content", text);
	}

	updateMeta(metaInfo: PageMetaInformation) {
		if (metaInfo.title)
			this.updateTitle(metaInfo.title);

		if (metaInfo.robots)
			this.updateRobots(metaInfo.robots);

		if (metaInfo.description)
			this.updateDescription(metaInfo.description);
	}

	private loadScripts(paths: string[]) {

		if (!this.shadowElements)
			throw new Error("Not mounted");

		const head = this.shadowElements.head;

		for (const path of paths) {

			const script = document.createElement("script");
			script.setAttribute("type", "text/javascript");
			script.setAttribute("src", path);

			head.appendChild(script);
		}
	}

	private loadStylesheets(paths: string[]) {

		if (!this.shadowElements)
			throw new Error("Not mounted");

		const toBeAdded: string[] = [];
		const toBeRemoved: string[] = [];

		// find stylesheets to be removed
		for (const key in this.loadedStylesheets) {
			if (!this.loadedStylesheets.hasOwnProperty(key))
				continue;

			if (!paths.includes(key))
				toBeRemoved.push(key);
		}

		// find stylesheets to be added
		for (const path of paths) {
			if (!this.loadedStylesheets.hasOwnProperty(path))
				toBeAdded.push(path);
		}

		// remove old stylesheets
		for (const rem of toBeRemoved) {
			const element = this.loadedStylesheets[rem];
			element.remove();

			logger(`Removing stylesheet ${rem}`);
			delete this.loadedStylesheets[rem];
		}

		// add new stylesheets
		for (const add of toBeAdded) {
			const link = document.createElement("link") as HTMLLinkElement;
			link.setAttribute("type", "text/css");
			link.setAttribute("rel", "stylesheet");
			link.setAttribute("href", add);

			logger(`Loading stylesheet ${add}`);
			this.shadowElements.head.appendChild(link);
			this.loadedStylesheets[add] = link;
		}
	}

	loadBody(html: string) {
		if (!this.shadowElements)
			throw new Error("Not mounted");

		this.shadowElements.body.innerHTML = html;
	}

	runInlineScripts() {
		if (!this.shadowElements)
			throw new Error("Not mounted");

		const scripts:NodeListOf<Element>=this.shadowElements.body.querySelectorAll('script');

		scripts.forEach((script: Element, key: any) => {
			//console.log('script', script.innerHTML);
			const execScript: Element = document.createElement('script');
			execScript.innerHTML=script.innerHTML;
			const parentElement=script.parentElement;
			if (parentElement!=null) {
				try {
                	parentElement.insertBefore(execScript, script);
                } catch (e) {
                    console.error(e, e.stack);
                }
            }
			script.parentElement!.removeChild(script);
		});

	}

	async refresh(throwOnPageNotFound:boolean): Promise<void> {
		if (!this.shadowRoot)
			await this.initializeShadowDom();

		const options: CmsApiFetchPageOptions = {
			invalidateCache: this.possiblyServerSideRenderingIssueMakeInvalidateCacheOpt(),
			reqdev: this.possiblyServerSideRenderingIssueMakeReqDevOpt(),
			pagePreviewOptions: this.possiblyServerSideRenderingIssueMakePreviewOpts(),
			cookies: {
				frontend: this.possiblyServerSideRenderingIssueGetCookie()
			}
		}

		const page:CmsApiFetchPageResponse = await this.options.api.fetchPage(document.location.pathname, options);

		if (throwOnPageNotFound && page.status===PageStatus.NotFound) {
			throw "Not found";
		}

		this.refreshFromPageResponse(page);

	}

	private nav(href: string, browserBackWorkaroundSkipPushState: boolean = false) {
		if (!browserBackWorkaroundSkipPushState) {
			try {
				history.pushState(null, "", href);
			} catch (e) {
				console.error("PushState failed", e);
				location.href = href;
				return;
			}
		}
		this.browserBackWorkaroundCurrentUrl = document.location.href;
		this.refresh(true)
			.then(value => {
				logger("Refresh completed");
				this.possiblyServerSideRenderingIssueScrollToTop();
			})
			.catch(reason => {
				if (href!=null) {
					logger("CMS failed, opening external...");
					location.href = href;
				} else {
					logger("Refresh failed:");
					logger(reason);
				}
			});
	}

	private refreshFromPageResponse(page:CmsApiFetchPageResponse) {

		this.updateMeta({
			title: page.title ? page.title : undefined,
			robots: page.robots ? page.robots : undefined,
			description: page.description ? page.description : undefined
		});

		this.loadStylesheets(page.stylesheets);

		this.loadBody(page.html);

		this.loadScripts(page.javascripts);

		this.runInlineScripts();

		this.interceptActions();

		this.possiblyServerSideRenderingIssueSetCmsCookie(page.cookie);

		this.possiblyServerSideRenderingIssueScrollToAnchor();

	}

	private interceptActions() {

		if (!this.shadowElements)
			throw new Error("Not mounted");

		// intercept links
		for (const link of this.shadowElements.body.querySelectorAll("a")) {

			const href = link.getAttribute("href");
			//const target = link.getAttribute("target");

			if (link.hasAttribute('data-lightbox')) {
				// cms element, do not try to navigate
				return;
			}

			if (href!=null) {
				link.addEventListener("click", (e: Event) => {
					e.preventDefault();
					this.nav(href);
				});
			}
		}

		// intercept forms
		for (const form of this.shadowElements.body.querySelectorAll("form")) {

			const handleFormSubmit = async () => {

				try {

					const action = form.getAttribute("action");

					if (action == null) {
						console.error("Could not get url from form");
						// this.setErrorMessage();
						return;
					}

					const method = form.getAttribute("method");
					const enctype = form.getAttribute("enctype");

					const formData: FormData = new FormData(form);

					// console.log('posting', action!, formData);

					const response = await this.options.api.postForm({
						url: action!,
						method: method == null ? 'post' : method.toLowerCase(),
						enctype: enctype == null ? 'application/x-www-form-urlencoded' : enctype.toLowerCase(),
						formData,
						cookie: this.possiblyServerSideRenderingIssueGetCookie()
					});
					// console.log('response', response);

					if (response.location) {
						try {
							history.pushState(null, '', response.location);
						} catch (e) {
							// i.e.
							//console.error(`Could not history.pushState(${response.location})`, e);
							location.href = response.location;
							return;
						}
						this.nav(response.location);
					} else {
						this.refreshFromPageResponse(response);
					}

				} catch (e2) {
					console.error(e2);
					throw e2;
				}

			};

			form.addEventListener("submit", (e: Event) => {
				e.preventDefault();
				handleFormSubmit().catch(reason => logger(reason));
			});

		}
	}

	possiblyServerSideRenderingIssueMakeInvalidateCacheOpt(): boolean {
		return document.location.search.indexOf("invalidateCache") !== -1;
	}

	possiblyServerSideRenderingIssueMakeReqDevOpt(): boolean {
		return document.location.search.indexOf("reqdev") !== -1;
	}

	possiblyServerSideRenderingIssueMakePreviewOpts(): PagePreviewOpts | NoPagePreviewOpts {
		if (document.location.search.indexOf("preview=true") === -1) {
			return NO_PREVIEW;
		}

		const params: Map<string, string> = new Map<string, string>();
		document.location.search
			.split("?")
			.forEach(s => {
				s
					.split("&")
					.forEach(s1 => {
						const nv = s1.split("=");
						if (nv.length == 2) {
							params.set(nv[0], nv[1]);
						} else if (nv.length == 1) {
							params.set(nv[0], "");
						}
					});
			})
		;

		return {
			preview: true,
			backendCookie: params.get("be-cookie") == undefined ? "" : params.get("be-cookie")!,
			frontendCookie: params.get("fe-cookie") == undefined ? "" : params.get("fe-cookie")!,
			showUnpublishedElements: document.location.search.indexOf("unpublished=true") > -1
		};
	}

	possiblyServerSideRenderingIssueSetCmsCookie(cookie: string) {
		if (cookie != null && cookie != undefined && cookie !== "") {
			localStorage.setItem("cms-cookie", cookie);
		}
	}

	possiblyServerSideRenderingIssueGetCookie(): string {
		const cookie: string | null = localStorage.getItem("cms-cookie");
		if (cookie === null || cookie === undefined) {
			return "";
		} else {
			return cookie!;
		}
	}

	private possiblyServerSideRenderingIssueScrollToAnchor() {

		if (!this.shadowElements)
			throw new Error("Not mounted");

		const hash=document.location.hash;
		if (!hash || !hash.match(/#.+$/)) {
			return;
		}

		// first try <a name="xxx">
		let el=this.shadowElements.body.querySelector(`a[name=${hash.replace("#", "")}]`);
		if (!el) {
			// try by id
			el = this.shadowElements.body.querySelector(hash);
		}

		if (!el) {
			console.warn(`Anchor ${hash} not found`);
			return;
		}

		window.setTimeout(() => {
			if (!el) {
				return;
			}
			try {
					const y = el.getBoundingClientRect().top + window.scrollY;
					window.scroll({top: y, behavior: 'smooth'});
			} catch (e) {
				console.error("Could not scroll to anchor", e);
			}
		}, 1000);

	}

	private loadFontAwesome() {
		var linkNode;

		linkNode = document.createElement("link");
		linkNode.type = "text/css";
		linkNode.rel = "stylesheet";
		linkNode.href = "https://de.pons.com/p/files/cto_layout/css/font-awesome.min.css";
		document.head.appendChild(linkNode);

		/*
		for (let fmt of ['eot', 'woff', 'ttf', 'svg']) {
			linkNode = document.createElement("link");
			linkNode.as = "font";
			linkNode.rel = "preload";
			linkNode.href = "https://de.pons.com/p/files/cto_layout/fonts/fontawesome-webfont." + fmt;
			document.head.appendChild(linkNode);
		}*/
	}

	private possiblyServerSideRenderingIssueScrollToTop() {
		let offset=0;
		let scroll = true;
		try {
			const topNode = document.querySelector("main");
			if (topNode) {
				const bodyTop = document.body.getBoundingClientRect().top;
				const elemTop = topNode.getBoundingClientRect().top;
				offset = elemTop - bodyTop;
				scroll = elemTop < 0; // only scroll if it's not visible
			}
		} catch (e) {
			logger("Could not detect scrollY", e);
		}
		if (scroll) {
			window.scrollTo({ top: offset, behavior: 'smooth' });
		}
	}

	// PW-649
	private initBrowserBackWorkaround() {
		this.browserBackWorkaroundCurrentUrl = document.location.href;
		this.browserBackWorkaroundInterval = window.setInterval(() => {
			const currentUrl = document.location.href;
			if (currentUrl !== this.browserBackWorkaroundCurrentUrl) {
				console.log("URL changed", this.browserBackWorkaroundCurrentUrl, currentUrl);
				this.browserBackWorkaroundCurrentUrl = currentUrl;
				this.nav(currentUrl, true);
			}
		}, 250);
	}

}