import {CmsApi, CmsApiFetchPageOptions, CmsApiFetchPageResponse, PostFormOpts} from "../CmsApi";
import "whatwg-fetch";
import debug from "debug";

const logger = debug("app:cms-api");

export interface CmsApiImplOptions {
	basePath: string;
}

function removeTrailingSlash(input: string): string {
	if (input.substring(input.length - 2) === "/")
		return input.substr(0, input.length - 1);
	else
		return input;
}

interface FetchPageBody {
	url: string;
	cookie: string;
	invalidateCache: boolean;
	previewOpts: {
		backendCookie?: string;
		frontendCookie?: string;
	};
}

export class CmsApiImpl implements CmsApi {

	private readonly baseUrl:string;

	constructor(private options: CmsApiImplOptions) {
		this.baseUrl = `${removeTrailingSlash(this.options.basePath)}`;
	}

	async postForm(options: PostFormOpts): Promise<CmsApiFetchPageResponse> {

		const formDataObj=new Object();
		for (let entry of options.formData.entries()) {
			if (typeof(entry[1])=='string') {
				Object.assign(formDataObj, {[entry[0]]: entry[1]});
			} else {
				console.log(typeof(entry[1]));
			}
		}

		const data=JSON.stringify(formDataObj);

		const body={
			url: options.url,
			method: options.method,
			enctype: options.enctype,
			data,
			cookie: options.cookie
		};

		const response = await this.doJsonPostRequest('/form', body);

		if (!response.ok)
			throw new Error("Request failed");

		const obj = await response.json();
		// console.log(obj);

		if (response.status >= 400) {

			if (obj["message"] !== undefined)
				throw Error(obj.message);
			else
				throw Error(response.statusText); // TODO: Error message
		}

		const cmsApiFetchPageResponse: CmsApiFetchPageResponse = obj;

		this.fixDownloadLinks(cmsApiFetchPageResponse);

		return cmsApiFetchPageResponse;

	}

	async fetchPage(path: string, options: CmsApiFetchPageOptions): Promise<CmsApiFetchPageResponse> {

		const body = {
			url: path,
			previewOpts: options.pagePreviewOptions,
			cookie: options.cookies['frontend'],
			invalidateCache: options.invalidateCache,
			reqdev: options.reqdev
		};

		const response = await this.doJsonPostRequest('/page', body);

		if (!response.ok) {
			if (response.status === 404) {
				// LSR-334: ok (show nice error page)
			} else {
				throw new Error("Request failed");
			}
		}

		const json=await response.json();
		const cmsApiFetchPageResponse: CmsApiFetchPageResponse = json;

		this.fixDownloadLinks(cmsApiFetchPageResponse);

		return cmsApiFetchPageResponse;
	}

	async doJsonPostRequest(relativeUrl: string, body: any, headers?: Record<string, string>): Promise<any> {

		if (headers === undefined)
			headers = {};

		if (!headers["Content-Type"])
			headers["Content-Type"] = "application/json";

		const url = `${this.baseUrl}${relativeUrl}`;
		logger(`fetching: ${url}`);

		return await fetch(url, {
			method: "post",
			mode: "cors",
			cache: 'no-cache',
			redirect: "error",
			credentials: "same-origin",
			headers,
			body: JSON.stringify(body)
		})
	}

	// LSR-351
	private fixDownloadLinks(response: CmsApiFetchPageResponse) {
		if (typeof response.html === typeof '') {
			response.html = response.html.replaceAll('href="/api/file/', `href="${this.baseUrl}/file/`);
		}
	}
}
